import './style.css';

import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IconAskForDetails,
  IconCheckBox,
  IconCheckBoxFull,
  IconDropDownList,
  IconMoreOptions,
  IconPDF,
  IconRadioMultiQuestionToTable,
  IconTextAnswerSmall,
  IconValidation,
} from '../../../../assets/img/icons';
import { SURVEY_TYPES } from '../../../../utils/dataMappers';
import { InputText, SurveyFormController } from '../../../components/formV3';
import { RichTextWithDraft } from '../../../components/formV3/controllers';
import Button from '../../../components/styledComponent/Button';
import { arrayToFormName } from '.';
import OnMoreInfo from './OnMoreInfo';
import Rewording from './Rewording';
import TextPdf from './TextPdf';

const QuestionHeader = ({
  question,
  children,
  onDelete,
  onMoreOptions,
  prefixName,
  can,
  disableRewording = false,
  hasRewording = false,
  suffixName = 'translation',
}) => {
  const { t } = useTranslation();
  const [isRewordingOpen, setIsRewordingOpen] = useState(false);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);
  const [isTextPdfOpen, setIsTextPdfOpen] = useState(false);
  const prefix = arrayToFormName([prefixName, suffixName]);

  const { control } = useFormContext(); // retrieve all hook methods
  const rewordingWatch = useWatch({
    control,
    name: arrayToFormName([prefixName, 'rewording']),
  });
  const image = (type) => {
    switch (type) {
      case SURVEY_TYPES.multiple_choice:
        return IconCheckBox;
      case SURVEY_TYPES.text_answer:
        return IconTextAnswerSmall;
      case SURVEY_TYPES.dropdown_list:
        return IconDropDownList;
      case SURVEY_TYPES.pdf:
        return IconPDF;
      case SURVEY_TYPES.checkbox_full:
        return IconCheckBoxFull;
      case SURVEY_TYPES.validation:
      case SURVEY_TYPES.radio_multi_questions_to_table:
        return IconRadioMultiQuestionToTable;
      default:
        return IconValidation;
    }
  };

  const handleMoreOptionsClick = () => {
    setIsMoreInfoOpen(false);
    if (onMoreOptions) {
      onMoreOptions();
    }
  };

  const handleDeleteQuestion = (e) => {
    if (onDelete) {
      onDelete(e);
    }
  };

  const handleOpenRewording = () => {
    setIsMoreInfoOpen(false);
    setIsRewordingOpen(true);
  };

  const handleCloseAll = () => {
    setIsMoreInfoOpen(false);
    setIsRewordingOpen(false);
    setIsTextPdfOpen(false);
  };

  const handleOpenTextPDFClick = () => {
    setIsMoreInfoOpen(false);
    setIsTextPdfOpen(true);
  };

  return (
    <div className="question-type-container d-flex flex-column align-items-start my-4">
      <SurveyFormController name={arrayToFormName([prefix, 'language_id'])} isRequiredField={true} className={'d-none'}>
        <InputText disabled={true} />
      </SurveyFormController>
      <div className="question-type-image-title-container d-flex align-items-center w-100 justify-content-between">
        <div className="question-type-image-container mr-2">
          <img src={image(question.type)} alt={question.type || 'image'} />
        </div>
        <SurveyFormController name={arrayToFormName([prefix, 'question'])} isRequiredField={true}>
          <InputText className="question-type-question-title" disabled={!can.steps.question.editQuestion} />
        </SurveyFormController>
        <div className="d-flex justify-content-end align-items-center">
          <Button type="button" onClick={handleOpenRewording} variant="ghost" className={`p-0 m-0 mx-1 ${!!rewordingWatch ? '' : 'invisible'}`}>
            <img src={IconAskForDetails} alt={t('rewording_card_title')} title={t('rewording_card_title')} />
          </Button>
          <Button type="button" onClick={() => setIsMoreInfoOpen((v) => !v)} variant="ghost" className="p-0 m-0 mx-1" style={{ minWidth: '66px' }}>
            <img
              className={`${can.steps.question.rewording.canEdit ? '' : 'invisible'} `}
              src={IconMoreOptions}
              alt={t('options')}
              title={t('options')}
            />
          </Button>
          {isMoreInfoOpen && (
            <OnMoreInfo
              onDeleteClick={handleDeleteQuestion}
              onDetailClick={handleOpenRewording}
              onTextPDFClick={handleOpenTextPDFClick}
              onClickOutside={handleMoreOptionsClick}
              disableRewording={disableRewording}
              hasRewording={hasRewording}
            />
          )}
        </div>
      </div>
      <div className={'question-type-question-inside-container w-100'}>
        <div className={'d-flex flex-column question-type-title-richtext-container'}>
          <SurveyFormController name={arrayToFormName([prefix, 'desc'])} isRequiredField={true}>
            <RichTextWithDraft editableOnClick={true} className="ec-rich-text-container-quill" />
          </SurveyFormController>
        </div>
        {children}
      </div>
      <Rewording isOpen={isRewordingOpen} disabled={!can.steps.question.rewording.canEdit} onExit={handleCloseAll} prefixName={prefixName} />
      <TextPdf
        isOpen={isTextPdfOpen}
        // disabled={!can.steps.question.rewording.canEdit}
        onExit={handleCloseAll}
        prefixName={prefixName}
      />
    </div>
  );
};

export default QuestionHeader;

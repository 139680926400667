import dayjs from 'dayjs';

export const MINIMUM_NUMBER_OF_DAYS = 3;

export class DateProvider {
  static isValidateDeadline(numberOfDays) {
    return dayjs().add(numberOfDays, 'days').isBefore(dayjs().add(MINIMUM_NUMBER_OF_DAYS, 'days'));
  }

  static getDeadline(numberOfDays) {
    if (numberOfDays) {
      return dayjs().add(numberOfDays, 'days').format('DD/MM/YYYY');
    }
    return '';
  }
}

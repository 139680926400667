import { format, startOfMonth, subMonths } from 'date-fns';
import _ from 'lodash';

import { SURVEY_STATUS } from '../utils/calimed-enum';
import { authenticatedMutation, authenticatedQuery } from './middleware';

/**
 * GRAPHQL QUERIES
 */
const queries = {
  GetMedicalTeam: () => `
    query GetMedicalTeam($id: Int) {
      medical_teams(where: {id: {_eq: $id}}) {
        created_at 
        updated_at
        id
        title
        description
        owner_id
        external_id
        role_id
        medical_teams_settings {
          id
          medical_team_id
          show_delay_reason
          is_security_number_hiden
          is_accept_multi_signer
          deadline_signing_representative
          deadline_signing_practitioner
        }
        medical_teams_medics {
          medic_id
          medical_teams_id
          medic {
            id
            firstname
            lastname
          }
        }
        surveys_aggregate(
          where: {
            status: {_in: ["${SURVEY_STATUS.ACCEPTED}", "${SURVEY_STATUS.COUNTERSIGNED}"]},
            is_signed: {_eq: true}, 
            updated_at: {
              _gte: "${format(subMonths(startOfMonth(new Date()), 1), 'yyyy/MM/dd')}", 
              _lt: "${format(startOfMonth(new Date()), 'yyyy/MM/dd')}"
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  medicalTeams: () => `
    query GetMedicalTeams {
      medical_teams {
        created_at 
        updated_at
        id
        title
        description
        owner_id
        external_id
        role_id
        medical_teams_settings {
          id
          medical_team_id
          show_delay_reason
          is_security_number_hiden
          is_accept_multi_signer
          deadline_signing_representative
          deadline_signing_practitioner
        }
        medical_teams_medics {
          medic_id
          medical_teams_id
          medic {
            id
            firstname
            lastname
          }
        }
        surveys_aggregate(
          where: {
            status: {_in: ["${SURVEY_STATUS.ACCEPTED}", "${SURVEY_STATUS.COUNTERSIGNED}"]},
            is_signed: {_eq: true}, 
            updated_at: {
              _gte: "${format(subMonths(startOfMonth(new Date()), 1), 'yyyy/MM/dd')}", 
              _lt: "${format(startOfMonth(new Date()), 'yyyy/MM/dd')}"
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
};
/**
 * GRAPHQL MUTATION
 *
 */

const mutations = {
  medicalTeamsCreate: () => `
    mutation MedicalTeamInsert($medicalTeam: [medical_teams_insert_input!]!) {
      insert_medical_teams(objects: $medicalTeam) {
        returning {
          id
        }
      }
    }
  `,
  medicalTeamsUpdate: () => `
    mutation MedicalTeamUpdateInsert($medicalTeamId: Int!, $medicalTeam: medical_teams_set_input!, $medicalTeamMedics: [medical_teams_medics_insert_input!]!, $medicalTeamSettings: medical_teams_settings_set_input!) {
      update_medical_teams(
        where: {
          id: {_eq: $medicalTeamId}
        }, 
        _set: $medicalTeam
      )
      {
        returning {
          id
        }
      }
      delete_medical_teams_medics(where: {medical_teams_id: {_eq: $medicalTeamId}}) {
        returning {
          medic_id
        }
      } 
      insert_medical_teams_medics(objects: $medicalTeamMedics) {
        returning {
          medic_id
          medical_teams_id
        }
      }
      update_medical_teams_settings(
        where: {
          medical_team_id: {_eq: $medicalTeamId}
        },
        _set: $medicalTeamSettings
      ) {
        returning {
          id
          medical_team_id
          show_delay_reason
          is_security_number_hiden
          is_accept_multi_signer
          deadline_signing_representative
          deadline_signing_practitioner
        }
      }
    }
  `,
  medicalTeamsDelete: () => `
    mutation MedicalTeamsDelete($ids: [Int!]!) {
      delete_survey_pdfs(where: {_or: [{survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}, {medic_template: {medical_teams_id: {_in: $ids}}}]}) {
        returning {
          id
        }
      }
      delete_medical_teams_settings(where: {medical_team_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_questions_translations(where: {questionByQuestionId: {step: {_or: [{medic_template_steps: {medic_template: {medical_teams_id: {_in: $ids}}}}, {survey_steps: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}}]}}}) {
        returning {
          id
        }
      }
      delete_questions(where: {step: {_or: [{medic_template_steps: {medic_template: {medical_teams_id: {_in: $ids}}}}, {survey_steps: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}}]}}) {
        returning {
          id
        }
      }
      delete_steps_translations(where: {step: {_or: [{medic_template_steps: {medic_template: {medical_teams_id: {_in: $ids}}}}, {survey_steps: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}}]}}) {
        returning {
          id
        }
      }
      delete_steps(where: {_or: [{medic_template_steps: {medic_template: {medical_teams_id: {_in: $ids}}}}, {survey_steps: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}}]}) {
        returning {
          id
        }
      }
      delete_medic_template_steps(where: {medic_template: {medical_teams_id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_survey_steps(where: {step: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}}) {
        returning {
          id
        }
      }
      delete_surveys_sides(where: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_surveys_radius(where: {survey: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_surveys(where: {is_signed: {_eq: false}, status: {_nin: ["accepted", "countersigned"]}, medical_teams_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_survey_attachments(where: {_not: {surveys: {}}}) {
        returning {
          id
        }
      }
      delete_survey_drawings(where: {_not: {surveys: {}}}) {
        returning {
          id
        }
      }
      updateSignedSurvey: update_surveys(where: {is_signed: {_eq: true}, status: {_in: ["accepted", "countersigned"]}, medical_team: {id: {_in: $ids}}}, _set: {medical_teams_id: null}) {
        returning {
          id
        }
      }
      updateaOthersSurvey: update_surveys(where: {medical_teams_id: {_in: $ids}}, _set: {medical_teams_id: null}) {
        returning {
          id
        }
      }
      delete_medic_templates_translations(where: {medic_template: {medical_teams_id: {_in: $ids}}}) {
        returning {
          id
        }
      }
      delete_medic_templates(where: {medical_teams_id: {_in: $ids}}) {
        returning {
          id
        }
      }
      delete_tutors(where: {patientByPatientId: {_and: [{medical_teams_id: {_in: $ids}}, {_not: {surveys: {}}}]}}) {
        returning {
          id
        }
      }
      delete_patients(where: {_and: [{medical_teams_id: {_in: $ids}}, {_not: {surveys: {}}}]}) {
        returning {
          id
        }
      }
      update_patients(where: {_and: [{medical_teams_id: {_in: $ids}}, {surveys: {medical_teams_id: {_nin: $ids}}}]}, _set: {medical_teams_id: null}) {
        returning {
          id
        }
      }
      updateOtherPatiens: update_patients(where: {medical_teams_id: {_in: $ids}}, _set: {medical_teams_id: null}) {
        returning {
          id
        }
      }
      delete_medical_teams_medics(where: {medical_teams_id: {_in: $ids}}) {
        returning {
          medic_id
        }
      }
      update_medics(where: {connector_medical_teams_id: {_in: $ids}}, _set: {connector_medical_teams_id: null}) {
        returning {
          id
        }
      }
      delete_medical_teams(where: {id: {_in: $ids}}) {
        returning {
          id
        }
      }
    }
  `,
  medicalTeamsMedicInsert: () => `
    mutation InsertMedicalTeamsMedics($objects: [medical_teams_medics_insert_input!]!) {
      insert_medical_teams_medics(objects: $objects) {
        returning {
          medical_teams_id
          medic_id
        }
      }
    }
  `,
};

export default {
  medicalTeam: (id) => authenticatedQuery(queries.GetMedicalTeam(), {}, { id }),
  medicalTeams: () => authenticatedQuery(queries.medicalTeams()),
  medicalTeamsCreate: (medicalTeam) => authenticatedMutation(mutations.medicalTeamsCreate(), {}, { medicalTeam }),
  medicalTeamsUpdate: (medicalTeam, medicalTeamMedics, medicalTeamSettings) =>
    authenticatedMutation(
      mutations.medicalTeamsUpdate(),
      {},
      {
        medicalTeamId: medicalTeam.id,
        medicalTeam: _.omit(medicalTeam, ['id']),
        medicalTeamMedics: medicalTeamMedics,
        medicalTeamSettings: medicalTeamSettings,
      },
    ),
  medicalTeamsDelete: (ids) => authenticatedMutation(mutations.medicalTeamsDelete(), {}, { ids }),

  medicalTeamsMedicInsert: (objects) => authenticatedQuery(mutations.medicalTeamsMedicInsert(), {}, { objects }),
};

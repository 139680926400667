export { default as IconPlus } from './icon-ajout-etape@3x.png';
export { default as IconMoreOptions } from './icon-more-option.svg';
export { default as IconValidation } from './icon-validation-button-radio.svg';
export { default as IconTextAnswerSmall } from './icon-reponse-texte.png';
export { default as IconMultipleChoiceSmall } from './icon-choix-multiple.png';
export { default as IconDropDownList } from './icon-liste-deroulante.svg';
export { default as IconBack } from './icon-back.svg';
export { default as IconDelete } from './icon-supprimer.svg';
export { default as IconAskForDetails } from './icon-ecrire-remarque.svg';
export { default as IconFindMore } from './icon-find.svg';
export { default as IconPDF } from './icon_pdf.svg';
export { default as IconCheckBox } from './checkbox.svg';
export { default as IconCheckBoxFull } from './checkbox-full.svg';
export { default as IconRadioMultiQuestionToTable } from './iconRadioMultiQuestionToTable.svg';

import './style.css';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CONTENT_TYPE_MAPPER } from '../../../../../utils/calimed-enum';
import { SurveyFormController } from '../../../../components/formV3';
import { FileUploader } from '../../../../components/formV3/controllers';
import QuestionHeader from '../QuestionHeader';
import SurveyDocumentViewer from '../SurveyDocumentViewer';

export const ACTIONS = {
  BUILD_PDF: 'build_pdf',
  BUILD_DRAW: 'build_draw',
  BUILD_ATTACHMENT: 'build_attachment',
  QUESTION_PDF: 'question_pdf',
};

const PDF = ({ question, onDelete, prefixName, index, nestIndex, disabled, can }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const translationPrefix = `${prefix}translation.`;
  const PDF_FIELD_NAME = `${translationPrefix}data`;

  const { t } = useTranslation();
  const { setValue, control } = useFormContext();
  const pdf = useWatch({
    control,
    name: PDF_FIELD_NAME, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });
  const [file, setFile] = useState(null);
  const [showPDF, setShowPDF] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [isFileRemoved, setIsFileRemoved] = useState(false);

  useEffect(() => {
    const fileList = Array.from(pdf?.pdf || []);
    const file = fileList.length && fileList[0];
    if (file && file.type) {
      setFile({ file: file });
      setIsFileRemoved(false);
      setValue(`${PDF_FIELD_NAME}`, { pdf: file, oid: -1 });
    }
  }, [pdf]);

  const onRemoveFile = (e) => {
    e.preventDefault();
    /*register(PDF_FIELD_NAME);*/
    setFile(null);
    setIsFileRemoved(true);
    setValue(`${PDF_FIELD_NAME}`, { pdf: null, oid: -1 });
  };

  const onHideFile = (e) => {
    e.preventDefault();
    setShowPDF(!showPDF);
  };

  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefix}
      index={index}
      nestIndex={nestIndex}
      disabled={disabled}
      disableRewording
      can={can}
    >
      <div className="ec-question-pdf-container">
        <>
          <SurveyFormController controlClassName={pdfLoading || file ? 'ec-question-pdf-hidden-button' : null} name={`${PDF_FIELD_NAME}.pdf`}>
            <FileUploader
              buttonTitle={t('upload_document')}
              buttonClassName="ec-question-pdf-button"
              disabled={disabled}
              accept={`${CONTENT_TYPE_MAPPER.PDF}`}
            />
          </SurveyFormController>
          <p className={`ec-question-pdf-loading-text ${!pdfLoading ? 'ec-question-pdf-hidden-button' : ''}`}>{t('document_loading')}</p>
          <div className="ec-question-pdf-buttons-container">
            <button
              disabled={disabled}
              className={`ec-question-pdf-button ${!file ? 'ec-question-pdf-hidden-button' : ''}`}
              onClick={onRemoveFile}
              type="button"
            >
              {t('delete_document')}
            </button>
            <button
              disabled={disabled}
              className={`ec-question-pdf-button ${!file ? 'ec-question-pdf-hidden-button' : ''}`}
              onClick={onHideFile}
              type="button"
            >
              {t(showPDF ? 'hide_document' : 'show_document')}
            </button>
          </div>
        </>
        <SurveyDocumentViewer
          // survey={currentSurvey}
          scale={0.8}
          disabled={disabled}
          customNavigation
          hidden={!showPDF}
          isFileRemoved={isFileRemoved}
          ioid={pdf && pdf.oid}
          forceDocument={file || false}
          action={ACTIONS.QUESTION_PDF}
          onFileLoaded={() => setPdfLoading(false)}
          showBtn={false}
        />
      </div>
    </QuestionHeader>
  );
};

export default PDF;

PDF.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};

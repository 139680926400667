import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { theme } from './config/theme';
import { Router, store } from './core';
import { GlobalLoader, SnackBar } from './ui/components';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router />
        <SnackBar />
        <GlobalLoader />
      </ThemeProvider>
    </Provider>
  );
}

export default App;

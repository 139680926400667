import './style.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { InputRadio } from './index';

const InputGroupedRadio = ({ name, options, isVertical, containerClassName, readOnly = false, ...rest }) => {
  return (
    <fieldset id={name}>
      <div className={`${containerClassName || 'input-radio-group'} ${isVertical ? 'vertical' : ''}`}>
        {options.map((o, index) => (
          <InputRadio key={`input-radio-group_${name}_${index}`} name={name} value={o.key} label={o.value} readOnly={readOnly} {...rest} />
        ))}
      </div>
    </fieldset>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.options
      .map((el) => el.value)
      .sort()
      .join() ===
    nextProps.options
      .map((el) => el.value)
      .sort()
      .join()
  );
};
/*
  return true if passing nextProps to render would return the same result as passing prevProps to render, otherwise return false
*/

InputGroupedRadio.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
  ),
};

export default React.memo(InputGroupedRadio, areEqual);

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import {
  IconCheckBox,
  IconCheckBoxFull,
  IconDropDownList,
  IconPDF,
  IconRadioMultiQuestionToTable,
  IconTextAnswerSmall,
  IconValidation,
} from '../../../../../assets/img/icons';
import { SURVEY_TYPES } from '../../../../../utils/dataMappers';

const typeToTooltip = {
  [SURVEY_TYPES.validation]: 'Validation',
  [SURVEY_TYPES.text_answer]: 'Réponse <br /> texte',
  [SURVEY_TYPES.multiple_choice]: 'Choix <br /> multiple',
  [SURVEY_TYPES.dropdown_list]: 'Liste <br /> déroulante',
  [SURVEY_TYPES.pdf]: 'PDF',
  [SURVEY_TYPES.checkbox_full]: 'Case <br /> à cocher',
  [SURVEY_TYPES.radio_multi_questions_to_table]: 'Case <br /> à cocher TO TABLE',
};

const Question = ({ type, text, disabled, onClick }) => {
  const onDragStart = (event) => {
    // UTILISER react-dnd POUR MEILLEUR EXPÉRIENCE
    event.dataTransfer.setData('text/plain', JSON.stringify(type));
  };

  const imageForType = useMemo(() => {
    const { value } = type;
    switch (value) {
      /*  case SURVEY_TYPES.validation:
          return IconValidation;*/
      case SURVEY_TYPES.text_answer:
        return IconTextAnswerSmall;
      case SURVEY_TYPES.multiple_choice:
        return IconCheckBox;
      case SURVEY_TYPES.dropdown_list:
        return IconDropDownList;
      case SURVEY_TYPES.pdf:
        return IconPDF;
      case SURVEY_TYPES.checkbox_full:
        return IconCheckBoxFull;
      case SURVEY_TYPES.radio_multi_questions_to_table:
        return IconRadioMultiQuestionToTable;
      default:
        return IconValidation;
    }
  }, [type]);
  return (
    <div
      className="createform-menu-single-question-container"
      draggable
      data-tip={typeToTooltip[type]}
      onClick={isMobile ? !disabled && onClick : undefined}
      onDragStart={(e) => onDragStart(e)}
    >
      <div className="createform-menu-single-question-image-container">
        <img src={imageForType} alt="createform-menu-single-question" />
      </div>
      <p className="createform-menu-single-question-text">{text}</p>
    </div>
  );
};

Question.propTypes = {
  type: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  }),
  text: PropTypes.string.isRequired,
};

export default Question;

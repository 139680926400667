import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import _ from 'lodash';

export const SURVEY_TYPES = {
  validation: 'validation',
  text_answer: 'text_answer',
  dropdown_list: 'dropdown_list',
  multiple_choice: 'multiple_choice',
  pdf: 'pdf',
  checkbox_full: 'checkbox_full',
  radio_multi_questions_to_table: 'radio_multi_questions_to_table',
};

export const QUESTION_TYPES = {
  validation: 'validation',
  text_answer: 'text_answer',
  dropdown_list: 'dropdown_list',
  multiple_choice: 'multiple_choice',
  pdf: 'pdf',
  checkbox_full: 'checkbox_full',
  radio_multi_questions_to_table: 'radio_multi_questions_to_table',
};

export const DISPLAYED_IF_MAPPER = {
  side: { value: 'side', label: 'Côté' },
  radius: { value: 'radius', label: 'Rayon' },
  date_warning: { value: 'date_warning', label: 'Délai de réflexion' },
};

export const MEDIC_TITLE = {
  dr: 'dr',
  pr: 'pr',
  mr: 'mr',
  ms: 'ms',
};

export const patientInfoMapper = (patient) => ({
  independent: patient.tutorsByPatientId && !patient.tutorsByPatientId.length,
  name: patient.firstname,
  nameOB: patient.lastname,
  numSocial: patient.security_number,
  sex: patient.gender,
  username: patient.use_name,
  birthdate: patient.birthdate,
});

export const patientContactMapper = (patient) => ({
  city: patient.city,
  email: patient.email || '',
  email_confirm: patient.email || '',
  portable: patient.phone_number,
  postal_code: patient.postcode,
});

export const tutorMapper = (tutor) => ({
  city: tutor.city,
  email: tutor.email || '',
  email_confirm: tutor.email || '',
  portable: tutor.phone_number,
  postal_code: tutor.postcode,
  name: tutor.firstname,
  nameOB: tutor.lastname,
  numSocial: tutor.security_number,
  sex: tutor.gender,
});

export const surveyStatusSorterMapper = {
  countersigned: 5,
  accepted: 4,
  refused: 3,
  sent: 2,
  draft: 1,
};

export const surveyStatusMapper = {
  refused: 2,
  sent: 1,
  draft: 0,
  accepted: 0,
  countersigned: 0,
};

export const surveyToSortable = (surveySteps) => {
  const columnOrder = surveySteps.map((s, sIndex) => ({
    id: `step.${sIndex}`, // shortid.generate(),
    title: s?.translation?.title || `step ${sIndex + 1}`,
  }));
  const itemsOrder = surveySteps
    .map((s, sIndex) => {
      return {
        [columnOrder[sIndex].id]: s.questions.map((q, qIndex) => ({
          id: `step.${sIndex}.questions.${qIndex}`,
          title: q?.translation?.question || `questions ${qIndex + 1} from step ${sIndex + 1}`,
        })),
      };
    })
    .reduce((acc, el) => ({ ...acc, ...el }), {});
  return {
    columnOrder,
    itemsOrder,
  };
};

export const listToSortable = (list) => {
  const lists = {};
  const items = {};
  //const listsIds = []
  const itemsIds = [];
  list.forEach((l, index) => {
    const id = `0-${index}`;
    itemsIds.push(id);
    items[id] = { id: id, content: l };
  });
  lists['0'] = { id: '0', title: '', itemsIds: itemsIds };
  return {
    items: items,
    lists: lists,
    listOrder: ['0'],
  };
};

export const sortableToList = (data) => {
  const newList = [];
  const { lists, items } = data;
  lists[0].itemsIds.forEach((itemId) => {
    newList.push(items[itemId].content);
  });
  return newList;
};

export const sortableToSurvey = (data, prevSteps) => {
  const { itemsOrder, columnOrder } = data;
  const newStepss = columnOrder.map((c, sIndex) => {
    return {
      ..._.get(prevSteps, [c.id.replace('step.', '')]),
      number: sIndex + 1,
      questions: itemsOrder[c.id].map((item, qIndex) => ({
        ..._.get(prevSteps, item.id.replace('step.', '')),
        number: qIndex + 1,
      })),
    };
  });
  return newStepss;
};

export const questionTypeValueToIdMapper = (types, type) => {
  if (!types || types === undefined || !types.length || !type || type === undefined) {
    return undefined;
  }
  return (types.filter((t) => t.value === type) || [{ id: undefined }])[0].id;
};

export const questionTypeIdToValueMapper = (types, type) => {
  if (!types || types === undefined || !types.length || !type || type === undefined) {
    return undefined;
  }
  return (types.filter((t) => t.id === type) || [{ value: undefined }])[0].value;
};

export const surveyTypeIdToValueMapper = (types, type) => {
  if (!types || types === undefined || !types.length || !type || type === undefined) {
    return undefined;
  }
  return (types.filter((t) => t.id === type) || [{ value: undefined }])[0].value;
};

export const phoneNumberMapper = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    phoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
    const parsePhoneNumber = phoneNumber && phoneUtil.parse(phoneNumber);
    const isValid = phoneNumber && phoneUtil.isValidNumber(parsePhoneNumber);
    return {
      phone_number: isValid ? phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.E164) : null,
      local_phone_number: isValid ? phoneUtil.format(parsePhoneNumber, PhoneNumberFormat.NATIONAL) : null,
    };
  } catch (e) {
    return {
      phone_number: null,
      local_phone_number: null,
    };
  }
};

import { Button } from 'antd';
import React from 'react';

const LoadMoreButton = ({ onClick }) => {
  return (
    <Button type="link" onClick={onClick}>
      Voir plus
    </Button>
  );
};

export default LoadMoreButton;

/* Services exports goes here */

export { default as AuthenticationService } from './authentication';
export { default as MedicsService } from './medicsService';
export { default as PatientService } from './PatientService';
export { default as ClinicsService } from './clinicsService';
export { default as ClinicsGroupService } from './clinicsGroupsService';
export { default as MedicalTeamsService } from './medicalTeamsService';
export { default as SurveyTypesService } from './surveyTypesService';
export { default as SurveysService } from './surveysService';
export { default as TemplatesService } from './templatesService';
export { default as MedicTemplatesService } from './medicTemplatesService';
export { default as ConsentsService } from './consentsService';
export { default as SpecialitiesService } from './specialitiesService';
export { default as FilesService } from './fileService';
export { default as InterventionsService } from './interventionsService';
export { default as QuestionTypesService } from './questionTypesService';
export { default as QuestionsService } from './questionsService';
export { default as StepsService } from './stepsService';
export { default as QuestionBasicsService } from './questionBasicsService';
export { default as LegalService } from './LegalService';
export { default as CityService } from './cityService';
export { default as CronService } from './cronService';
export { default as UserService } from './userService';
export { default as IntroductionQuestionService } from './introductionQuestionsService';
export { default as RolesService } from './rolesService';
export { default as SmsService } from './smsService';
export { default as SurveyModelFilesService } from './surveyModelFilesService';

export { default as EmailsService } from './emailsService';
export { default as UserRolesService } from './userRolesService';
export { default as AppKeysService } from './appKeysService';
export { default as SftpCredentialService } from './sftpCredentials.service.js';

import { MINIMUM_NUMBER_OF_DAYS } from '../date/DateProvider';

export const MESSAGE_ERROR_DEADLINE = 'elle doit être au moins de 3 jours de plus à la date du jour';
export const LABEL_DEADLINE_REPRESENTATIVE = 'Date limite de signature du représentant en jour';
export const LABEL_DEADLINE_PRACTITIONER = 'Date limite de signature du praticien en jour';
export const MULTI_SIGNER_MODE = 'Mode multi-signataire';
export const MULTI_SIGNER_BLOCK = 'Multi signataire';

/**
 * Validation of Signing Dates
 * @param {boolean} canShowDeadline
 * @param {Function} callback
 * @return {[{validator},{message: string, required}]}
 */
export const validateNumberOfDayPicker = (canShowDeadline, callback) => [
  {
    validator: callback,
  },
  { required: canShowDeadline, message: 'Nombre de jours non spécifié' },
];

/**
 * Check if medical team has settings
 * @param {object} medicalTeam
 * @return {boolean}
 */
export const hasMedicalTeamsSettings = (medicalTeam) =>
  Array.isArray(medicalTeam.medical_teams_settings) && medicalTeam.medical_teams_settings && medicalTeam.medical_teams_settings.length;

/**
 * init medical team
 * @param medicalTeam
 * @return {*&{medic_id: any[], medical_teams_settings: ((*&{deadline_signing_practitioner: dayjs.Dayjs|null, deadline_signing_representative: dayjs.Dayjs|null})[]|*[])}}
 */
export const initMedicalTeam = (medicalTeam) => {
  const newMedicalTeams = medicalTeam.medical_teams_medics;
  const getMinimumDeadline = (deadline) => {
    if (deadline === 0) {
      return deadline + MINIMUM_NUMBER_OF_DAYS;
    }
    return deadline;
  };

  return {
    ...medicalTeam,
    medic_id: Array.isArray(newMedicalTeams) && newMedicalTeams.map((m) => m.medic_id),
    medical_teams_settings: hasMedicalTeamsSettings(medicalTeam)
      ? medicalTeam.medical_teams_settings.map((el) => ({
          ...el,
          deadline_signing_representative: getMinimumDeadline(el.deadline_signing_representative),
          deadline_signing_practitioner: getMinimumDeadline(el.deadline_signing_practitioner),
        }))
      : [],
  };
};

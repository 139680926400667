import '../style.css';

import PropTypes from 'prop-types';
import React from 'react';

import { InputText, SurveyFormController } from '../../../../components/formV3';
import ErrorMessage from '../../../../components/formV3/errors/ErrorMessage';
import { arrayToFormName } from '..';
import QuestionHeader from '../QuestionHeader';

export const VALIDATION_KEYS = {
  FIRST: 'first',
  SECOND: 'second',
};

const Validation = ({ question, onDelete, prefixName, index, nestIndex, can, localId }) => {
  const prefix = prefixName ? `${prefixName}.` : '';
  const ANSWER_DATA_FORM_NAME = arrayToFormName([prefixName, 'translation', 'data']);
  const translationPrefix = `${prefix}`;
  return (
    <QuestionHeader question={question} onDelete={onDelete} prefixName={translationPrefix} index={index} nestIndex={nestIndex} can={can} hasRewording>
      {Object.values(VALIDATION_KEYS).map((key) => (
        <div key={key} className="d-flex align-items-center mb-2">
          <span className="input-radio-input-vertical" />
          <div className="mx-2 w-100 mw-75">
            <SurveyFormController name={arrayToFormName([ANSWER_DATA_FORM_NAME, key])} hideError>
              <InputText disabled={!can.steps.question.data.canEdit} className="input-radio-value-vertical" />
            </SurveyFormController>
          </div>
        </div>
      ))}
      <ErrorMessage name={`${translationPrefix}data`} />
    </QuestionHeader>
  );
};

export default Validation;

Validation.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
  can: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/named
import { Button, Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { getUserProperty } from '../../../utils';
import { DateProvider, MINIMUM_NUMBER_OF_DAYS } from '../../../utils/date/DateProvider';
import {
  hasMedicalTeamsSettings,
  initMedicalTeam,
  LABEL_DEADLINE_PRACTITIONER,
  LABEL_DEADLINE_REPRESENTATIVE,
  MESSAGE_ERROR_DEADLINE,
  MULTI_SIGNER_BLOCK,
  MULTI_SIGNER_MODE,
  validateNumberOfDayPicker,
} from '../../../utils/medicalTeams';
import ReactSelect from '../ReactSelect/ReactSelect';

const MedicalTeamsForm = ({ onValidate, values, medics, rolesOptions, clinicOptions }) => {
  const [medicalTeams, setMedicalTeams] = useState([]);
  const [medicsById, setMedicsById] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [canShowDeadline, setCanShowDeadline] = useState(false);

  useEffect(() => {
    setMedicsById(_.keyBy(medics, 'id'));
  }, [medics]);

  useEffect(() => {
    if (values) {
      const newMedicalTeams = values.medical_teams_medics;
      setMedicalTeams(newMedicalTeams);
      const newInitialValues = initMedicalTeam(values);
      if (!_.isEqual(initialValues, newInitialValues)) {
        setInitialValues(newInitialValues);
        if (hasMedicalTeamsSettings(newInitialValues)) {
          setCanShowDeadline(newInitialValues.medical_teams_settings[0]['is_accept_multi_signer']);
        }
      }
    }
  }, [initialValues, values]);

  useEffect(() => {
    if (initialValues) {
      form.resetFields();
    }
  }, [form, initialValues]);

  const onFinish = (values) => {
    onValidate(values);
  };

  const { TextArea } = Input;

  const DSC = 'descending';

  function sortByText(a, b, order = DSC) {
    if (a && b) {
      const diff = _.deburr(a.lastname.toLowerCase()).localeCompare(_.deburr(b.lastname.toLowerCase()));

      if (order === DSC) {
        return diff;
      }

      return -1 * diff;
    }
  }

  const handleValueChange = (changedValues) => {
    if (changedValues && 'medic_id' in changedValues) {
      // get medics from medicsById based on id list inside changedValues['medic_id']
      const medic_idList = changedValues['medic_id'];
      const newMedicalTeams =
        medic_idList && medic_idList.length
          ? _.chain(medicsById)
              .pick(changedValues['medic_id'])
              .map((el) => ({ medic: el }))
              .value()
          : [];
      if (newMedicalTeams.filter((v) => v.id === ownerId).length === 0) {
        form.resetFields(['owner_id']);
      }
      setMedicalTeams(newMedicalTeams);
    }
    if (changedValues && 'owner_id' in changedValues) {
      setOwnerId(changedValues['owner_id']);
    }

    if (changedValues && 'medical_teams_settings' in changedValues && 'is_accept_multi_signer' in changedValues['medical_teams_settings'][0]) {
      setCanShowDeadline((s) => !s);
    }
  };

  const validateDeadline = useCallback((_rule, value) => {
    if (DateProvider.isValidateDeadline(value)) {
      return Promise.reject(MESSAGE_ERROR_DEADLINE);
    }
    return Promise.resolve();
  }, []);

  return (
    <Card style={{ margin: 25, width: '80%' }}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        layout="vertical"
        // onFieldsChange={handleOnChange}
        onValuesChange={handleValueChange}
      >
        <Form.Item name="title" label="Nom de l'équipe" rules={[{ required: true, message: 'Nom Incorrect' }]}>
          <Input placeholder="Nom de l'équipe" maxLength={50} data-testid="MedicalTeamsForm-input-title" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea placeholder="Description" autoSize={{ minRows: 5, maxRows: 14 }} maxLength={20000} />
        </Form.Item>
        <Form.Item name="medic_id" label="Qui sont les membres ?" rules={[{ required: true, message: 'Membre(s) non spécifié(s)' }]}>
          <ReactSelect
            placeholder="Qui sont les membres ?"
            isMulti
            closeMenuOnSelect={false}
            name="medic_id"
            isSearchable
            options={
              Array.isArray(medics) &&
              medics
                .sort((a, b) => sortByText(a, b, DSC))
                .map((m) => ({
                  value: m.id,
                  label: getUserProperty(m, 'fullContactName'),
                }))
            }
          />
        </Form.Item>
        <Form.Item name="owner_id" label="Qui est le propriétaire ?" rules={[{ required: true, message: 'Propriétaire non spécifié' }]}>
          <ReactSelect
            placeholder="Qui est le propriétaire ?"
            name="owner_id"
            isSearchable
            options={
              Array.isArray(medicalTeams)
                ? medicalTeams
                    .sort((a, b) => sortByText(a.medic, b.medic, DSC))
                    .map((m) => ({
                      value: m.medic && m.medic.id,
                      label: getUserProperty(m.medic, 'fullContactName'),
                    }))
                : []
            }
          />
        </Form.Item>
        <Divider type="horizontal">Role</Divider>
        <Form.Item name={'role_id'} label="Détail du role" rules={[{ required: true, message: 'role non spécifiée' }]}>
          <Select
            style={{ width: '100%' }}
            // mode="multiple"
          >
            {rolesOptions.map((r) => (
              <Select.Option key={r.value} value={r.value}>
                {r.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="clinic_id" label="A quel établissement est-il rattaché ?">
          <ReactSelect placeholder="A quel établissement est-il rattaché ?" closeMenuOnSelect={true} isSearchable options={clinicOptions} />
        </Form.Item>
        <Divider type="horizontal">Paramètres de l'équipe de médicale</Divider>
        <Form.Item name={['medical_teams_settings', '0', 'show_delay_reason']} valuePropName="checked">
          <Checkbox>Demander un justificatif lorsque le délai consultation/intervention est trop court</Checkbox>
        </Form.Item>
        <Form.Item name={['medical_teams_settings', '0', 'is_security_number_hiden']} valuePropName="checked">
          <Checkbox>Masquer le numéro de sécurité sociale</Checkbox>
        </Form.Item>
        <Divider type="horizontal">{MULTI_SIGNER_BLOCK}</Divider>
        <Row>
          <Col sm={24}>
            <Form.Item name={['medical_teams_settings', '0', 'is_accept_multi_signer']} valuePropName="checked">
              <Checkbox>{MULTI_SIGNER_MODE}</Checkbox>
            </Form.Item>
          </Col>
          {canShowDeadline && (
            <Col sm={24}>
              <Row justify="space-around">
                <Col>
                  <Form.Item
                    name={['medical_teams_settings', '0', 'deadline_signing_representative']}
                    label={LABEL_DEADLINE_REPRESENTATIVE}
                    rules={validateNumberOfDayPicker(canShowDeadline, validateDeadline)}
                  >
                    <InputNumber
                      size="small"
                      addonAfter={<span>jours</span>}
                      min={MINIMUM_NUMBER_OF_DAYS}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={['medical_teams_settings', '0', 'deadline_signing_practitioner']}
                    label={LABEL_DEADLINE_PRACTITIONER}
                    rules={validateNumberOfDayPicker(canShowDeadline, validateDeadline)}
                  >
                    <InputNumber
                      size="small"
                      addonAfter={<span>jours</span>}
                      min={MINIMUM_NUMBER_OF_DAYS}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Divider type="horizontal">Connecteur</Divider>
        <Form.Item name="external_id" label="Id externe" rules={[]}>
          <Input placeholder="Id externe" maxLength={50} disabled />
        </Form.Item>

        <Divider type="horizontal" />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ width: '55%' }} data-testid="MedicalTeamsForm-submit-btn">
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default MedicalTeamsForm;

export { default as Home } from './home';
export { default as Authentication } from './authentication';
export { default as Medics } from './medics';
export { default as MedicsCreate } from './medicsCreate';
export { default as Patients } from './patients';
export { default as PatientsCreate } from './patientsCreate';
export { default as Clinics } from './clinics';
export { default as ClinicsCreate } from './clinicsCreate';
export { default as Consents } from './consents';
export { default as Credential } from './Credential';
export { default as CredentialCreate } from './CredentialCreate';
export { default as ConsentsCreate } from './consentsCreate';
export { default as Specialities } from './specialities';
export { default as SpecialitiesCreate } from './specialitiesCreate';
export { default as Surveys } from './surveys';
export { default as SurveysCreateV2 } from './SurveysCreateV2';
export { default as Templates } from './templates';
export { default as MedicalTeams } from './medicalTeams';
export { default as MedicalTeamsCreate } from './medicalTeamsCreate';
export { default as SurveyTypes } from './surveyTypes';
export { default as SurveyTypesCreate } from './surveyTypesCreate';
export { default as QuestionTypes } from './questionTypes';
export { default as QuestionTypesCreate } from './questionTypesCreate';
export { default as Legal } from './legal';
export { default as Roles } from './roles';
export { default as RolesCreate } from './rolesCreate';
export { default as Sftp } from './sftpCredential';
export { default as SftpCreate } from './sftpCredentialCreate';
export { default as Sms } from './sms';
export { default as SmsCreate } from './smsCreate';
export { default as Emails } from './emails';
export { default as EmailsCreate } from './emailsCreate';
export { default as AppKeys } from './app_keys';
export { default as AppKeysCreate } from './appKeysCreate';
export { default as Language } from './Language';
export { default as LanguageCreate } from './LanguageCreate';
export { default as MsWordTemplate } from './MsWordTemplate';
export { default as MsWordTemplateCreate } from './MsWordTemplateCreate';
export { default as ClinicsGroups } from './clinicsGroups';
export { default as ClinicsGroupsCreate } from './clinicsGroupsCreate';

import { FilePdfOutlined } from '@ant-design/icons';
import React from 'react';

const PdfButton = ({ pdf }) => {
  return (
    <a>
      <FilePdfOutlined style={{ color: 'red' }} onClick={() => pdf()} />
    </a>
  );
};

export default PdfButton;

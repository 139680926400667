export default {
  common: {
    ConsentRender: {
      answer: { required: 'Réponse requise' },
      dropdown_list: { required: 'Réponse requise' },
      multiple_choice: { required: 'Réponse requise' },
      text_answer: { required: 'Réponse requise' },
      checkbox_full: { required: 'Pour pouvoir signer votre consentement, toutes les cases doivent être cochées.' },
      radio_multi_questions_to_table: { required: 'Réponse requise.' },
      pdf: { isRead: 'Le PDF doit être lu entièrement.' },
      validation: { required: 'Vous devez valider la question afin de poursuivre' },
      rejection_message: {
        intro: 'Vous avez déclarer ne pas vouloir poursuivre votre consentement',
        question: "En effet, malgré les explications complémentaires, vous n'avez pas compris la question {{ question }}",
        refused: 'Vous avez déclaré ne pas vouloir poursuivre votre consentement',
        'further information': "Vous avez déclaré avoir besoin d'informations supplémentaires pour signer votre consentement",
      },
    },
    sideMenu: {
      gestion: 'Gestion',
      title: {
        home: {
          root: 'Home',
        },
        pro: {
          root: 'Praticiens',
          management: 'Gestion praticiens',
          btnTitle: 'Ajouter un praticien',
        },
        patient: {
          root: 'Patients',
          management: 'Gestion patients',
          btnTitle: 'Ajouter un Patient',
        },
        clinic: {
          root: 'Etablissements',
          management: 'Gestion Etablissements',
          btnTitle: 'Ajouter un Etablissement',
        },
        clinicsGroups: {
          root: 'Groupes de Cliniques',
          management: 'Gestion des groupes de cliniques',
          btnTitle: 'Ajouter un groupe de cliniques',
        },
        medicalTeams: {
          root: 'Equipes médicales',
          management: 'Gestion Equipes médicales',
          btnTitle: 'Ajouter une equipe médicale',
        },
        forms: {
          root: 'Formulaires',
          management: 'Gestion Formulaires',
          btnTitle: 'Ajouter un formulaires',
        },
        templates: {
          root: 'Store Templates',
          management: 'Gestion Templates',
          btnTitle: 'Ajouter un template',
        },
        medicTemplates: {
          root: 'Medic Templates',
          management: 'Gestion medic templates',
          btnTitle: 'Ajouter un medic template',
        },
        surveyTypes: {
          root: 'Types',
          management: 'Gestion types',
          btnTitle: 'Ajouter un type',
        },
        msWordTemplates: {
          root: 'Templates Words',
          management: 'Gestion templates Words',
          btnTitle: 'Ajouter un templates Words',
        },
        consents: {
          root: 'Consentements',
          management: 'Gestion consentements',
          btnTitle: 'Ajouter un consentement',
        },
        specialities: {
          root: 'Spécialités',
          management: 'Gestion spécialités',
          btnTitle: 'Ajouter une spécialité',
        },
        cgu: {
          root: 'CGU',
          management: 'Gestion CGU',
          btnTitle: 'Ajouter une CGU',
        },
        rgpd: {
          root: 'RGPD',
          management: 'Gestion RGPD',
          btnTitle: 'Ajouter un RGPD',
        },
        roles: {
          root: 'Roles',
          management: 'Gestion roles',
          btnTitle: 'Ajouter un roles',
        },
        app_keys: {
          root: 'Clé API',
          management: 'Gestion clé API',
          btnTitle: 'Ajouter une clé API',
        },
        sftpCredentials: {
          root: 'SFTP',
          management: 'Gestion SFTP',
          btnTitle: 'Ajouter un SFTP',
        },
        credentials: {
          root: 'Credential',
          management: 'Gestion credential',
          btnTitle: 'Ajouter un credential',
        },
        languages: {
          root: 'Languages',
          management: 'Gestion languages',
          btnTitle: 'Ajouter un language',
        },
        list: {
          root: 'Liste',
          management: 'Gestion liste',
          btnTitle: 'Ajouter une liste',
        },
        config: {
          root: 'Config',
          management: 'Gestion config',
          btnTitle: 'Ajouter une config',
        },
        emailsSms: {
          root: 'E-mails / SMS',
        },
        emails: {
          root: 'E-mails',
          management: 'Gestion E-mails',
          btnTitle: 'Ajouter un E-mails',
        },
        sms: {
          root: 'SMS',
          management: 'Gestion SMS',
          btnTitle: 'Ajouter un SMS',
        },
      },
    },
    richtext: {
      preview: 'Aperçu',
      click_here: 'Cliquer ici pour editer le texte',
    },
    pdfViewer: {
      'previous page': 'Page précédente',
      'next page': 'Page suivante',
      'Page x on xx': 'Page {{page}} sur {{pages}}',
    },
    create_patients_error: 'La création du patient a echoué',
    create_patient_success: 'Patient créé',
    /* Generic Message */
    unauthorized: "Vous n'êtes pas autorisé à afficher cette page, veuillez vous connecter.",
    authentication_failed: 'Login ou mot de passe incorrect.',
    generic_error: "Une erreur inconnue s'est produite lors du chargement des données.",
    fields_missing: 'Merci de renseigner tous les champs du formulaire.',
    save: 'Enregistrer',
    delete: 'Supprimer',
    SMS: 'SMS',
    Mail: 'Mail',
    preview: 'Preview',
    attached_a_pdf: 'Joindre un PDF',
    attach_image: 'Joindre une image',
    default_form_data: {
      step: {
        title: "Titre de l'étape",
      },
      questions: {
        multiple_choice: {
          question: 'Votre Question Choix Multiple',
          list: { firstChoice: 'Premier choix' },
        },
        text_answer: {
          question: 'Votre Question Texte',
        },
        dropdown_list: {
          question: 'Votre Question Menu déroulant',
          list: { firstChoice: 'Premier choix' },
        },
        pdf: {
          question: 'Votre question PDF',
        },
        checkbox_full: {
          question: 'Votre question cases à cocher',
          list: { firstChoice: 'Premier choix' },
        },
        radio_multi_questions_to_table: {
          question: 'Votre question cases à cocher',
          list: { yes: 'Oui', no: 'Non', notApplicable: 'Non applicable', notConcerned: 'Non concerné' },
        },
        validation: {
          question: 'Votre Question Validation',
          data: { first: "Oui, j'ai compris", second: "Non, je désire de l'information supplémentaire" },
        },
      },
    },
    /* Buttons */
    sign_in: "JE M'IDENTIFIE",
    connect: 'CONNEXION',

    /* Authentication */
    email_placeholder: 'Adresse E-mail',
    password_placeholder: 'Mot de passe',
    invalid_credentials: 'Email et/ou mot de passe invalide',
    forgotten_password: 'Mot de passe oublié',

    /* Password */
    password_modify_error: 'La modification de votre mot de passe a échouée',
    password_modify_success: 'Votre mot de passe a été modifié avec succès',

    /* Email */
    email_sent_error: "L'envoi de l'email a échouée",
    email_sent_success: 'Votre email a été envoyé avec succès',

    /* Medics */
    medic_list_error: 'Votre liste ne peux pas être actualisée',
    medic_modify_success: 'Votre praticien a bien été modifié',
    medic_modify_error: 'Votre praticien ne peux pas être modifié',
    medic_delete_success: 'Votre praticien a bien été supprimé',
    medic_delete_error: 'Votre praticien ne peux pas être supprimé',
    medic_create_success: 'Votre praticien a bien été créé',
    medic_create_error: 'Votre praticien ne peux pas être créé',

    /* Patients */
    patient_list_error: 'Votre liste ne peux pas être actualisée',
    patient_fetch_error: 'Votre patient ne peux pas être chargé',
    patient_modify_success: 'Votre patient a bien été modifié',
    patient_modify_error: 'Votre patient ne peux pas être modifié',
    patient_delete_success: 'Votre patient a bien été supprimé',
    patient_delete_error: 'Votre patient ne peux pas être supprimé',
    patient_create_success: 'Votre patient a bien été créé',
    patient_create_error: 'Votre patient ne peux pas être créé',
    patient_create_error_mail: "Votre patient ne peux pas être créé, l'adresse email est déjà utilisée",
    gender_male: 'Homme',
    gender_female: 'Femme',

    /* Clinics */
    clinic_list_error: 'Votre liste ne peux pas être actualisée',
    clinic_modify_success: 'Votre établissement a bien été modifié',
    clinic_modify_error: 'Votre établissement ne peux pas être modifié',
    clinic_delete_success: 'Votre établissement a bien été supprimé',
    clinic_delete_error: 'Votre établissement ne peux pas être supprimé',
    clinic_create_success: 'Votre établissement a bien été créé',
    clinic_create_error: 'Votre établissement ne peux pas être créé',

    /* Credentials */
    credential_request_error: "Le credential n'a pas pu être récupéré",
    credential_upsert_error: 'Votre credential ne peux pas être modifié',

    /* MedicalTeams */
    medicalTeam_list_error: 'Votre liste ne peux pas être actualisée',
    medicalTeam_modify_success: 'Votre équipe médicale a bien été modifiée',
    medicalTeam_modify_error: 'Votre équipe médicale ne peux pas être modifiée',
    medicalTeam_delete_success: 'Votre équipe médicale a bien été supprimée',
    medicalTeam_delete_error: 'Votre équipe médicale ne peux pas être supprimée',
    medicalTeam_create_success: 'Votre équipe médicale a bien été créée',
    medicalTeam_create_error: 'Votre équipe médicale ne peux pas être créée',

    /* SurveyTypes */
    surveyType_list_error: 'Votre liste ne peux pas être actualisée',
    surveyType_modify_success: 'Votre catégorie a bien été modifiée',
    surveyType_modify_error: 'Votre catégorie ne peux pas être modifiée',
    surveyType_delete_success: 'Votre catégorie a bien été supprimée',
    surveyType_delete_error: 'Votre catégorie ne peux pas être supprimée',
    surveyType_create_success: 'Votre catégorie a bien été créée',
    surveyType_create_error: 'Votre catégorie ne peux pas être créée',
    surveyType_cron_upsert_error: "Une erreur a eu lieu pendant l'édition de vos rappels",
    add_a_cron: 'Ajouter un rappel',
    cron_config_error: 'Erreur lors de la récupération des données de configuration des rappels',
    interventions_date_consult: 'Date de consultation',
    interventions_date: "Date de l'intervention",
    surveys_created_at: 'Date de création du consentement',
    surveys_referent_medic_id: "Praticien référent de l'intervention",
    surveys_patient_id: "Patient de l'intervention",
    surveys_medic_surveys_medic: "Praticiens de l'intervention",
    template_filename_label: 'Nom du template mail à utiliser pour le rappel',
    template_filename_placeholder: 'Nom du template',
    template_filename_required: 'Le nom du template mail est obligatoire',
    cron_date_label: 'Date de référence du rappel',
    cron_date_placeholder: 'Date de référence',
    cron_date_required: 'La date de référence du rappel est obligatoire',
    cron_offset_label: 'Nombre de jours avant/aprés la date de référence où envoyer le rappel',
    cron_offset_placeholder: 'Nombre de jours',
    cron_offset_required: 'Le nombre de jours avant/aprés la date de référence est obligatoire',
    cron_communication_label: 'Type de rappel',
    cron_communication_placeholder: 'Type de rappel',
    cron_communication_required: 'Le type de rappel est obligatoire',
    cron_recipient_label: 'Destinataire(s) du rappel',
    cron_recipient_placeholder: 'Destinataire',
    cron_recipient_required: 'Le.s destinataire.s du rappel est.sont nécessaire.s',

    /* QuestionTypes */
    questionType_list_error: 'Votre liste ne peux pas être actualisée',
    questionType_modify_success: 'Votre question a bien été modifiée',
    questionType_modify_error: 'Votre question ne peux pas être modifiée',
    questionType_delete_success: 'Votre question a bien été supprimée',
    questionType_delete_error: 'Votre question ne peux pas être supprimée',
    questionType_create_success: 'Votre question a bien été créée',
    questionType_create_error: 'Votre question ne peux pas être créée',

    /* Steps */
    step_list_error: 'Votre liste ne peux pas être actualisée',
    step_modify_success: 'Votre étape a bien été modifiée',
    step_modify_error: 'Votre étape ne peux pas être modifiée',
    step_delete_success: 'Votre étape a bien été supprimée',
    step_delete_error: 'Votre étape ne peux pas être supprimée',
    step_create_success: 'Votre étape a bien été créée',
    step_create_error: 'Votre étape ne peux pas être créée',

    /* Steps */
    question_list_error: 'Votre liste ne peux pas être actualisée',
    question_modify_success: 'Votre question a bien été modifiée',
    question_modify_error: 'Votre question ne peux pas être modifiée',
    question_delete_success: 'Votre question a bien été supprimée',
    question_delete_error: 'Votre question ne peux pas être supprimée',
    question_create_success: 'Votre question a bien été créée',
    question_create_error: 'Votre question ne peux pas être créée',

    /* Roles */
    role_list_error: 'Votre liste ne peux pas être actualisée',
    role_modify_success: 'Votre role a bien été modifiée',
    role_modify_error: 'Votre role ne peux pas être modifiée',
    role_delete_success: 'Votre role a bien été supprimée',
    role_delete_error: 'Votre role ne peux pas être supprimée',
    role_create_success: 'Votre role a bien été créée',
    role_create_error: 'Votre role ne peux pas être créée',

    /* User */
    user_pswd_updated_error: 'La MAJ du mot de passe a échoué',
    user_pswd_updated_success: 'Mot de passe MAJ avec succès',

    /* Surveys */
    survey_list_error: 'Votre liste ne peux pas être actualisé',
    survey_modify_success: 'Votre formulaire a bien été modifié',
    survey_modify_error: 'Votre formulaire ne peux pas être modifié',
    survey_delete_success: 'Votre formulaire a bien été supprimé',
    survey_delete_error: 'Votre formulaire ne peux pas être supprimé',
    survey_duplicate_success: 'Votre formulaire a bien été dupliqué',
    survey_duplicate_error: 'Votre formulaire ne peux pas être dupliqué',
    survey_create_success: 'Votre formulaire a bien été créé',
    survey_create_error: 'Votre formulaire ne peux pas être créé',
    template_published_update_failure: 'La mise à jours du status de publication a échoué',
    template_published_update_success: 'Mise à jours du status de publication effectuée',

    /* Consents */
    consent_list_error: 'Votre liste ne peux pas être actualisée',
    consent_resume_success: 'La consentement est relancé',
    consent_resume_error: "La consentement n'est pas relancé",

    /* Interventions */
    intervention_create_error: "Votre consentement et intervention n'ont pu être créés",
    intervention_create_success: 'Votre consentement et intervention ont été créés avec succès',
    intervention_modify_error: "Votre consentement et intervention n'ont pu être modifiés",
    intervention_modify_success: 'Votre consentement et intervention ont été modifiés avec succès',
    intervention_delete_error: "Votre consentement et intervention n'ont pu être supprimés",
    intervention_delete_success: 'Votre consentement et intervention ont été supprimés avec succès',
    intervention_mail_error: "Le mail n'a pas pu être envoyé",
    intervention_mail_success: 'Mail envoyé avec succès',
    intervention_archived_error: "Erreur lors de l'archivage des consentements",
    intervention_unarchived_error: 'Erreur lors du désarchivage des consentements',
    intervention_archived_success: 'Archivage des consentements effectués',
    intervention_unarchived_success: 'Désarchivage des consentements effectués',

    /* Specialities */
    speciality_list_error: 'Votre liste ne peux pas être actualisée',
    speciality_create_error: 'Votre spécialité na pu être créée',
    speciality_create_success: 'Votre spécialité a été créée avec succès',
    speciality_modify_error: "Votre spécialité n'a pu être modifiée",
    speciality_modify_success: 'Votre spécialité a été modifiée avec succès',
    speciality_delete_error: "Votre spécialité n'a pu être supprimée",
    speciality_delete_success: 'Votre spécialité a été supprimée avec succès',

    cancel_form_creation: 'Annuler la création',
    radius_choice: 'Choix du rayon',
    side_choice: 'Choix du coté',
    form_step_title: "Titre de l'étape",
    form_screen_slide_a_componant: 'Glisser un composant ici',
    form_screen_drop_a_componant: 'Déposer le composant ici',
    question_types: 'Types de questions',
    reorder_translation: 'Réorganiser',
    search_for_questions: 'Chercher question',
    a_step_translation: 'Une étape',
    reorder_your_form: 'Réorganiser votre formulaire',
    question_type_validation: 'Validation',
    question_type_text_answer: 'Réponse texte',
    question_type_multiple_choice: 'Choix multiple',
    question_type_dropdown_list: 'Liste déroulante',
    question_type_pdf: 'PDF',
    question_type_checkbox_full: 'Cases à cocher',
    question_type_radio_multi_questions_to_table: 'Cases à cocher to table',
    form_screen_write_details: 'Ajouter une reformulation',
    form_screen_delete_question: 'Supprimer la question',
    form_screen_add_pdf: 'Question sur le pdf',
    options: 'Options',
    rewording_card_title: 'Reformulation',
    texte_pdf_modal_title: 'Text PDF',
    validate: 'Valider',
    cancel: 'Annuler',
    ok: 'OK',
    form_screen_title_required: 'Requis',
    form_screen_type_required: 'Requis',
    num_social_size: 'Le numéro de sécurité social doit être composé de 15 chiffres.',
    num_social_format: "Le format du numéro de sécurité social n'est pas valide.",
    num_social_size_not_valid: "Le numéro de sécurité social renseigné n'est pas valide.",
    num_social_key_not_valid: "La clé n'est pas valide.",
    template_update_error: "L'édition du formulaire courant a échoué",
    template_update_error_large_file: "L'édition du formulaire courant a échoué due à une image trop volumineuse",
    template_update_success: "L'édition du formulaire courant est effectué",
    question_basics_modify_success: 'Votre question préleminaire a bien été créée',
    question_basics_modify_error: 'Votre question préleminaire ne peux pas être créée',
    cgu_list_error: 'Votre liste ne peux pas être actualisée',
    cgu_modify_success: 'Votre CGU a été modifié avec succès',
    cgu_modify_error: "Votre CGU n'a pu être modifié",
    reset_cgu_patient_success: 'Les CGU des patients ont été réinitialisé',
    reset_cgu_medic_success: 'Les CGU des praticiens ont été réinitialisé',
    reset_cgu_all_success: 'Les CGU ont été réinitialisé',
    rgpd_list_error: 'Votre liste ne peux pas être actualisée',
    rgpd_modify_success: 'Votre RGPD a été modifié avec succès',
    rgpd_modify_error: "Votre RGPD n'a pu être modifié",
    rgpd_btn_reset_patient: 'Reset RGPD patient',
    rgpd_btn_reset_pro: 'Reset RGPD praticien',
    rgpd_btn_reset_all: 'Reset tous les RGPD',
    cgu_btn_reset_patient: 'Reset CGU patient',
    cgu_btn_reset_pro: 'Reset CGU praticien',
    cgu_btn_reset_all: 'Reset tous les CGU',
    reset_rgpd_patient_success: 'Les RGPD des patients ont été réinitialisé',
    reset_rgpd_medic_success: 'Les RGPD des praticiens ont été réinitialisé',
    reset_rgpd_all_success: 'Les RGPD ont été réinitialisé',
    reset_error: 'La réinitialisation a échouée',
    proof_modify_success: 'La convention de preuve a été modifiée avec succés',
    proof_modify_error: "La convention de preuve n'as pas pu être mis à jours",

    medic_link_to_surveys_error: 'Une erreur a eu lieu lors de la liaison du praticien aux formulaires demandés',

    medic_template_get_error: 'Une erreur a eu lieu pendant la récupération des formulaires praticiens',
    medic_template_get_success: 'Récupération des formulaires praticiens effectuées',

    current_medic_template_get_error: 'Une erreur a eu lieu pendant la récupération du formulaire',
    current_medic_template_get_success: 'Récupération du formulaire effectuée',

    medic_template_upsert_error: "Une erreur a eu lieu lors de l'enregistrement de votre formulare",
    medic_template_upsert_success: 'Enregistrement de votre formulaire effectué',

    medic_template_delete_error: 'Une erreur a eu lieu lors de la suppression des formulaires',
    medic_template_delete_success: 'Suppression de vos formulaires effectuées',

    medic_template_duplicate_error: 'Une erreur a eu lieu lors de la copie des formulaires',
    medic_template_duplicate_success: 'Copie de vos formulaires effectuées',

    medic_template_generate_pdf_success: 'Génération du PDF effectuée',
    medic_template_generate_pdf_failure: 'Erreur lors de la génération du PDF',

    delete_document: 'Supprimer le document',
    hide_document: 'Cacher le document',
    show_document: 'Voir le document',
    upload_document: 'Charger nouveau document',
    document_loading: 'Votre document est en cours de chargement...',

    refused: 'Refusé',
    sent: 'En cours',
    accepted: 'Signé',
    signed: 'Signé',

    Yes: 'Oui',
    No: 'Non',

    mail_error_invalid_email: "Le email fournit n'est pas valide",
    sms_error_invalid_email: "Le numéro de téléphone fournit n'est pas valide",
    patientCreate: {
      resetPswdCountBtn: {
        title: 'Réinitialiser le compteur de connexion',
      },
    },
    emailForm: {
      type: {
        label: 'Type',
        rules: {
          required: 'Type incorrect',
        },
      },
      subject: {
        label: 'Sujet',
        placeholder: 'Sujet...',
        rules: {
          required: 'Sujet du mail incorrect',
        },
      },
      hidden_text: {
        label: 'Text caché pour affichage preview',
        placeholder: 'Text caché pour affichage preview...',
      },
      intro_paragraph: {
        label: "Paragraphe d'intro",
        placeholder: "Paragraphe d'intro...",
        rules: {
          required: 'Paragraph du mail incorrect',
        },
      },
      btn_title: {
        label: 'Bouton - Texte à afficher',
        placeholder: 'Texte à afficher du bouton (vide = btn absent)',
      },
      btn_href: {
        label: 'Bouton - URL',
        placeholder: '{{redirect_front_url}}...',
      },
      link_title: {
        label: 'Lien - Texte à afficher',
        placeholder: 'Texte à afficher du lien (vide = lien absent)...',
      },
      link_href: {
        label: 'Lien - URL',
        placeholder: '{{redirect_front_url}}...',
      },
      before_sign_paragraph: {
        label: 'Paragraph avant signature',
        placeholder: 'Paragraph avant signature...',
      },
      body: {
        label: "Corps de l'e-mail",
        placeholder: "Corps de l'e-mail...",
      },
      save: 'Enregistrer',
    },
    roleForm: {
      divider: {
        info: 'Info',
        clinicalStudy: 'Etudes cliniques',
        permissions: 'Permissions',
        customPermissions: 'Permissions personalisee',
      },
      permissions: {
        manage: 'Tout faire',
        create: 'Créer',
        read: 'Lire',
        update: 'Modifier',
        delete: 'Supprimer',
        access: 'Acceder',
      },
      fields: {
        name: {
          label: 'Titre',
          placeholder: 'Titre',
        },
        banned_questionTypes: {
          label: 'Types de questions innaccessibles',
        },
        scope: {
          label: 'Ce role est accessible pour (type de user)',
        },
        can_read_medic_templates: {
          label: 'Peut éditer un template',
        },
        can_create_medic_templates: {
          label: 'Peut créer un template',
        },
        banned_frontUrls: {
          label: 'Liste des URL frontend innaccessibles',
        },
        permissions_on_medicTemplates: {
          label: 'Actions possibles sur un medic template',
        },
        permissions_on_templates: {
          label: 'Actions possibles sur un template (store)',
        },
        owner_permissions_on_medicTemplates: {
          label: 'Le propriétaire du medic template peut',
        },
        banned_patientFields: {
          label: 'Les champs patients masqués',
        },
      },
    },
    surveyForm: {
      button: {
        cancel: { label: 'Annuler' },
        send: { label: 'Valider' },
      },
      consent_pdf_template: {
        label: 'Modèle PDF',
        placeholder: 'Sélectionner le modèle PDF pour le génération du consentement',
        rules: {
          required: 'Le modèle PDF doit être renseigné.',
          format: 'Le modèle PDF ne doit contenir que des lettres.',
        },
      },
      title: {
        label: 'Titre du formulaire',
        rules: {
          required: 'Le Titre doit être renseigné.',
          format: 'Le Titre  ne doit contenir que des lettres.',
        },
      },
      type: {
        label: "Pour quel type d'intervention",
        rules: {
          required: "Le type d'intervention doit être renseigné.",
          format: "Le type d'intervention ne doit contenir que des lettres.",
        },
      },
      steps: {
        rules: {
          min: 'Un formulaire doit contenir au moins une étape.',
        },
        title: {
          rules: {
            required: 'Le titre doit être renseigné.',
            format: 'Le titre ne doit contenir que des lettres.',
          },
        },
        comment: {
          rules: {
            required: 'Le détail de la question doit être renseigné.',
            format: "Le détail de la question n'est pas au bon format.",
          },
        },
        questions: {
          rules: {
            min: 'Une étape doit contenir au moins une question.',
          },
          question: {
            rules: {
              required: 'Le titre doit être renseigné.',
              format: 'Le titre ne doit contenir que des lettres.',
            },
          },
          desc: {
            rules: {
              required: 'La description doit être renseignée.',
              format: 'Le titre ne doit contenir que des lettres.',
            },
          },
          validation: {
            first: {
              required: 'Le champs doit être renseigné.',
            },
          },
        },
      },
    },
    medic_title: {
      dr: 'Dr.',
      pr: 'Pr.',
      mr: 'M.',
      ms: 'Mme.',
    },
    dateFns: {
      'age not specified': 'Âge non renseigné',
      fromNowDays_zero: 'Âge non renseigné',
      fromNowDays_one: '{{count}}: jour',
      fromNowDays_other: '{{count}}: jours',
      fromNowMonths: '{{count}} mois',
      fromNowYears_zero: '{{count}} an',
      fromNowYears_one: '{{count}} an',
      fromNowYears_other: '{{count}} ans',
    },
  },
};

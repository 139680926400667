import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MedicalTeamsForm from '../../components/medicalTeamsForm/MedicalTeamsForm';

const MedicalTeamsCreate = ({
  medicalTeamsCreateRequest,
  medicalTeamsUpdateRequest,
  rolesRequest,
  medicsRequest,
  medicalTeamsRequest,
  rolesOptions,
  medicalTeamsList,
  medicsList,
  clinicsRequest,
  clinicOptions,
}) => {
  const { medicalTeamId } = useParams();
  const id = medicalTeamId && !['medicalTeams', 'add'].includes(medicalTeamId) ? medicalTeamId : null;
  const form = useMemo(() => {
    return id && medicalTeamsList && medicalTeamsList[id];
  }, [id, medicalTeamsList]);

  useEffect(() => {
    medicsRequest();
    medicalTeamsRequest({ id });
    rolesRequest({ isBackend: false });
    clinicsRequest();
  }, [clinicsRequest, id, medicalTeamsRequest, medicsRequest, rolesRequest]);

  const onValidate = useCallback(
    (value) => {
      if (form == null) {
        medicalTeamsCreateRequest(value);
      } else {
        value.id = form.id;
        medicalTeamsUpdateRequest(value);
      }
    },
    [form, medicalTeamsCreateRequest, medicalTeamsUpdateRequest],
  );

  return (
    <>
      {form && form.id && form.id !== '' && <div>Consentement signé(s) le mois dernier : {form && form.surveys_aggregate.aggregate.count}</div>}

      <MedicalTeamsForm
        onValidate={onValidate}
        values={_.omit(form, ['surveys_aggregate'])}
        // eslint-disable-next-line no-console
        onError={(err) => console.error(err)}
        medics={Object.values(medicsList)}
        rolesOptions={rolesOptions}
        clinicOptions={clinicOptions}
      />
    </>
  );
};

export default MedicalTeamsCreate;

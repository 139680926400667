export const resolveId = (router) => {
  const id = router.location.pathname.split('/').pop();
  return id && !['add'].includes(id) ? id : null;
};

export const resolveQueryParamFromSearch = (locationSearch, keys) => {
  const query = new URLSearchParams(locationSearch);
  let search = {};

  for (let i = 0, len = keys.length; i < len; ++i) {
    const value = query.get(keys[i]);
    if (value) {
      search[keys[i]] = decodeURIComponent(value);
    }
  }

  return search;
};

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { shortidGenerate } from '../../../../../utils/utils';
import { InputGroupedRadio, SurveyFormController } from '../../../../components/formV3';
import { arrayToFormName } from '..';
import ArrayList from '../ArrayList';
import QuestionHeader from '../QuestionHeader';

const RadioMultiQuestionsToTable = ({ question, onDelete, prefixName, index, nestIndex, readOnly, disabled, initialValues, isConsent, can }) => {
  const options = _.get(question, 'translation.data.list', []);
  return (
    <QuestionHeader
      question={question}
      onDelete={onDelete}
      prefixName={prefixName}
      index={index}
      nestIndex={nestIndex}
      disabled={disabled}
      initialValues={initialValues}
      isConsent={isConsent}
      can={can}
    >
      {isConsent ? (
        <SurveyFormController name={arrayToFormName([prefixName, 'answer'])} isRequiredField={true} classNameError="form-item-error-text-dropdown">
          <InputGroupedRadio options={options} containerClassName="input-radio-group" className="input-radio-value" readOnly={readOnly} />
        </SurveyFormController>
      ) : (
        <ArrayList
          prefixName={arrayToFormName([prefixName, 'translation', 'data'])}
          sufixNameInput={'value'}
          name={'list'}
          defaultNewItem={{ key: shortidGenerate(), value: '' }}
          deletableItems
          disabled={disabled}
          isTextArea
        />
      )}
    </QuestionHeader>
  );
};

export default RadioMultiQuestionsToTable;

RadioMultiQuestionsToTable.propTypes = {
  question: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  prefixName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  nestIndex: PropTypes.number,
};

import '../style.scss';

import { CheckboxGroup } from '@trendmicro/react-checkbox';
import { Checkbox } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function InputGroupedCheckbox({ name, label, rules = { required: false }, options, isDisable, defaultValue, className, ...rest }) {
  const { control } = useFormContext(); // retrieve all hook methods
  const {
    field, // field : { onChange, onBlur, value, name: fieldName, ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty, error },
    // formState: { errors, isDirty, isSubmitting, touched, submitCount },
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || _.get(control, ['defaultValuesRef', 'current', name]) || '',
  });

  return (
    <CheckboxGroup depth={4} disabled={isDisable} {...field} {...rest}>
      {options.map((l, index) => (
        <div key={`multiple_choices_question_type_choice_${l}_${index}`} className={className}>
          <Checkbox label={l} value={l} />
          <br />
        </div>
      ))}
    </CheckboxGroup>
  );
}

InputGroupedCheckbox.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default InputGroupedCheckbox;
